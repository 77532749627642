<template>
  <div class="box">
    <div class="logo">
      <img src="@/img/logo-icon.png" alt="" />
    </div>
    <div class="text">
      &nbsp;&nbsp;&nbsp;&nbsp;微企利乐，创立于首都北京，在国家帮扶企业的战略红海中应运而生，专注于大数据分析和金融科技研发，聚焦小微深耕实体，用科技缩短人和金融服务的距离，让更多普通企业能公平享受到社会普惠金融服务。<br />
      &nbsp;&nbsp;&nbsp;&nbsp;公司创立前期以智能金融服务方案为切入口，依托强大的技术优势、运营能力，通过大数据风控系统，实现为金融机构高效发掘、筛选优质客户；同时整合所有银行产品，基于AI智能算法，为企业精准匹配，并无缝链接申贷平台，成为国内领先的金融科技综合服务商。
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.box {
  line-height: 30px;
  padding: 15px;
  width: 345px;
  margin: 0 auto;
  padding-top: 66px;
  font-size: 12px;
  .logo {
    width: 82px;
    height: 82px;
    margin: 0 auto;
    margin-bottom: 53px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .title {
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
